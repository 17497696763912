.MuiTabs-root {
    min-height: 30px;
}

.MuiTab-root {
    min-height: 30px;
}

.MuiButtonBase-root {
    padding: 0px;
}

.divSuccesIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.cardImage {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
}

.cardImageInfo {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
}

@media only screen and (max-width: 600px) {
    .cardImage {
        margin-left: 0;
        margin-right: 0;
    }
}

.scoreCircle {
    display: flex;
    flex-direction: column;
    width: 52px;
    height: 52px;
    background: #606368;
    border: 1px solid #919EAB;
    border-radius: 1000px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #FFF;
}

.scoreDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}